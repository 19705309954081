import React, { Fragment, useEffect, useState } from 'react'
import useConsentReady from '../../../Sourcepoint/modules/use-consent-ready'
import useScript from 'react-script-hook'
import consoleLogger from '../../../../modules/console-logger'
import isServerSide from '../../../../modules/is-server-side'
import Safe from 'react-safe'
import styled from 'styled-components'
import handlerWindowDlab from '../../../../bundles/BundleAds/modules/handler-window-dlab'

const Wrapper = styled.div`
  padding: ${props => props.theme.sizes.gapEdge};
  @media print {
    display: none;
  }
`

const Taboola = ({...props}) => {
  const [consoleLog] = consoleLogger('Taboola')
  const { dlabOption } = handlerWindowDlab('ads', { sub: 'Taboola' })
  const consentReady = useConsentReady()
  const [loading, error] = useScript({
    src: consentReady ? '//cdn.taboola.com/libtrc/firstavenuegmbh-stolit/loader.js' : null,
    checkForExisting: true,
    id: 'tb_loader_script',
    async: 1
  })
  const [firstLoad, setFirstLoad] = useState(false)
  useEffect(() => {
    consoleLog('window var Taboola', window._taboola)
    window._taboola = window._taboola || [];

    if (!loading && consentReady && window._taboola && !firstLoad) {
      if (!dlabOption["start"]) {
        window._taboola.push({article:'auto'});
        if(window.performance && typeof window.performance.mark == 'function')
        {
          window.performance.mark('tbl_ic');
        }
        consoleLog('first page load', 'success')
      }
      if (dlabOption["start"]) {
        window._taboola.push({notify:'newPageLoad'});
        window._taboola.push({article:'auto', url:location?.href});
        consoleLog('new page load', 'success')
      }
      window._taboola.push({mode: 'alternating-thumbnails-a', container: 'taboola-below-article-thumbnails', placement: 'Below Article Thumbnails', target_type: 'mix'});
      consoleLog('load widget', 'success')
      dlabOption["start"] = true
      setFirstLoad(true)
    }

  }, [loading, consentReady])

  return (
    <Wrapper {...props}>
      {!isServerSide() && (
        <Fragment>
          <Safe.div id="taboola-below-article-thumbnails" />
        </Fragment>
      )}
    </Wrapper>
  )
}
export default Taboola
