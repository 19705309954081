import Outbrain from '../../../../../../vendor/AdThirdParty/components/Outbrain'
import React from 'react'
import abTestResult from '../../../../../../bundles/BundleAds/modules/ab-test'
import pushEventToDataLayer from '../../../../../../modules/push-event-to-data-layer'
import Taboola from '../../../../../../vendor/AdThirdParty/components/Taboola'

const ReadingRecommendation = ({location}) => {
  const result = abTestResult({id: 'ReadingRecommendation', percentTraffic: 0.5, type: 'session'})

  const analyticsData = [
    ['Location', location]
  ]
  if (result.a || true) {
    pushEventToDataLayer('ReadingRecommendation', ['Result', 'Taboola'], ...analyticsData)
    return (
      <Taboola location={location} />
    )
  }
  pushEventToDataLayer('ReadingRecommendation', ['Result', 'Outbrain'], ...analyticsData)
  return (
    <Outbrain location={location} />
  )
}

export default ReadingRecommendation
